<template>
  <div class="select-none cont_popUpClientes flex items-start justify-center flex-wrap flex-row-reverse w-[90vw] xl:w-[80vw] 2xl:w-[60vw] " >
    <div class="block absolute top-[2rem] right-[2rem] select-none cont_btnCerrarPopUpClientes cursor-pointer " @click="OpcionesPopUp[0].Seleccionado ? cerrarPopUp() : VolverOpcionesMenuPopUp()">
      <i class="select-none fa-solid md:scale-150 border rounded-xl py-1 px-2 flex flex-col items-center justify-center w-[30px] h-[30px] text-white" :class="OpcionesPopUp[0].Seleccionado ? 'fa-xmark' : 'fa-arrow-left'"></i>
    </div>
    <div class="cont_MenuPpal_PopUpClientes" v-if="OpcionesPopUp[0].Seleccionado">
      <div  class=" flex 
                    w-[90vw] 
                    h-[10rem] 
                    p-[2rem] 
                    m-[1rem] 
                    md:w-[30rem] 
                    md:h-[10rem] 
                    md:p-[2rem] 
                    md:m-[1rem] 
                    items-center 
                    justify-around 
                    content-center 
                    flex-nowrap 
                    flex-row 
                    text-black
                    text-left 
                    font-semibold 
                    rounded-2xl 
                    overflow-hidden 
                    cursor-pointer 
                    duration-500 
                    ease-out 
                    hover:scale-105
                    cont_OpcionMenuPopUp" 
            v-for="(opcion,index) in OpcionesMenuPopUp" 
            :key="opcion.id"
            @click="setOpcionMenuSeleccionado(opcion.id)">
        <span class="text-xl text-center w-[60%]"> {{ opcion.Descipcion }} </span>
        <div class="duration-500 ease-out cont_imgOpcionMenuPopUp w-[40%]">
          <img class="w-[full]" :src="opcion.ImgURL">
        </div>
      </div>
    </div>
    <CarruselOfertas v-if="!OpcionesPopUp[0].Seleccionado" :OpcionesPopUp="OpcionesPopUp"/>
  </div>
</template>

<script>
import CarruselOfertas from '@/components/Main/CarruselOfertas.vue'
import router from '@/router';
import { computed, ref } from "@vue/runtime-core";
import { watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'PopUpClientes',
    components: {
      CarruselOfertas
    },
    setup(){
        const store = useStore()
        
        const cerrarPopUp = () => {
          store.state.mostrarPopUpClientes = false
        }
        const dataUsuario = computed(() => store.state.dataUsuario)
        const infoCliente = computed(() => store.state.dataUsuario.infoCliente)
        const Campana = ref("")
        const leaderList = computed(() => store.state.leaderList)

        const OpcionesPopUp = ref([
          {id: 0, PaginaLL: undefined, FolderURL: undefined, ImgURL: undefined, ColorFondoInicial: "#000000", ColorFondoFinal: "#000000", Seleccionado: true, Descipcion: "MENU PRINCIPAL"},
          {id: 1, PaginaLL: 1, FolderURL: "PopUp", ImgURL: "https://juanabonitavirtual.com.co/img/JB/IconOfertasUltimoMinuto.webp", ColorFondoInicial: "#b81430", ColorFondoFinal: "#f93a5a", Seleccionado: false, Descipcion: "OFERTA ÚLTIMO MINUTO"},
          {id: 2, PaginaLL: undefined, FolderURL: undefined, ImgURL: "https://juanabonitavirtual.com.co/img/JB/IconAgotadosDisponibles.webp", ColorFondoInicial: "#517316", ColorFondoFinal: "#81b723", Seleccionado: false, Descipcion: "AGOTADOS DISPONIBLES CAMPAÑA ANTERIOR"},
          {id: 3, PaginaLL: 162, FolderURL: "TipoB", ImgURL: "https://juanabonitavirtual.com.co/img/JB/IconPaquetesTipoB.webp", ColorFondoInicial: "#876f2a", ColorFondoFinal: "#fcd230", Seleccionado: false, Descipcion: "PAQUETES TIPO B"}
        ])

        const setOpcionMenuSeleccionado = (index) => {
          if(index == 2){
            setMostrarBackOrder()
            cerrarPopUp()
          }else{
            OpcionesPopUp.value[0].Seleccionado = false
            OpcionesPopUp.value[index].Seleccionado = true
          }
        }
        
        const VolverOpcionesMenuPopUp = () => {
          OpcionesPopUp.value[0].Seleccionado = true
          for (let index = 1; index < OpcionesPopUp.value.length; index++) {
            OpcionesPopUp.value[index].Seleccionado = false
          }
        }
        const OpcionesMenuPopUp = computed(() => {return OpcionesPopUp.value.filter((el) => {return el.id > 0})})

        const EsperarElementoDOM = async (Seleccionador) => {
          return new Promise(resolve => {
            if (document.querySelector(Seleccionador)) {
              return resolve(document.querySelector(Seleccionador));
            }
            const Observador = new MutationObserver(Mutaciones => {
              if (document.querySelector(Seleccionador)) {
                Observador.disconnect();
                resolve(document.querySelector(Seleccionador));
              }
            });
            Observador.observe(document.body, {
              childList: true,
              subtree: true
            });
          });
        }

        const setCampana = () => {
          Campana.value = dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa
          //console.log(dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa)
        }

        watch(dataUsuario, (N,V) => {
          if(N.infoCliente != undefined){
            setCampana()
          }
        })

        if(dataUsuario.value.infoCliente != undefined){
          setCampana()
        }

        watch(dataUsuario, (N,V) => {
          //console.log(N)
          if(N.length != undefined || N.length > 0){
            setCampana()
          }
        })

        const cerrarSesion = () => {
          Swal.fire({
            icon: 'warning',
            title: 'ATENCIÓN!',
            text: "Expiró la sesion"
          })
          store.state.Cargando = true
          Cookies.remove("UsuarioJB")
          store.state.Targets = undefined
          store.state.Generos = undefined
          store.state.Categorias = undefined
          store.state.Grupos = undefined
          store.state.generoSeleccionadoHover = undefined
          store.state.categoriaSeleccionadaHover = undefined
          store.state.grupoSeleccionadoHover = undefined
          store.state.generoSeleccionadoProductos = undefined
          store.state.categoriaSeleccionadaProductos = undefined
          store.state.grupoSeleccionadoProductos = undefined
          store.state.generoSeleccionado = undefined
          store.state.categoriaSeleccionada = undefined
          store.state.grupoSeleccionado = undefined
          store.state.paginaSeleccionada = undefined
          store.state.productosCarrito = []
          store.state.sesionActiva = false
          store.state.esCliente = false
          store.state.modoCompra = false
          store.state.tienePedido = false
          store.state.Cargando = false
          store.state.estadoPedido = 0
          store.state.anuncio = ""
          router.push("/")
        }

        const setMostrarBackOrder = () => {
          store.state.Cargando = true
          let token = ((Cookies.get('UsuarioJB')) != undefined) && ((Cookies.get('UsuarioJB')) != null) && ((Cookies.get('UsuarioJB')) != "") ? JSON.parse(Cookies.get('UsuarioJB')) : undefined
          //console.log(token)
          if(token == undefined){
            cerrarSesion()
          }else{
            axios
              .get(store.state.urlApi + "/api/PedidosClientes/GetBackOrderCliente/" + infoCliente.value.NumCodigo + "/" + Campana.value , {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token.token,
                },
              })
              .then((response) => {
                //console.log(response.data)
                store.state.Cargando = false
                store.state.productosBackOrder = response.data
                router.push("/AgotadosDisponibles")
              })
              .catch((error) => {
                store.state.Cargando = false
                store.state.productosBackOrder = []
                router.push("/AgotadosDisponibles")
              })
          }
        }

        return{
          cerrarPopUp,
          Campana,
          setMostrarBackOrder,
          OpcionesPopUp,
          OpcionesMenuPopUp,
          setOpcionMenuSeleccionado,
          VolverOpcionesMenuPopUp
        }
    }
}
</script>

<style>
.cont_MenuPpal_PopUpClientes{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.cont_OpcionMenuPopUp {
  background: linear-gradient(90deg, rgba(216,188,91,1) 0%, rgba(169,123,45,1) 60%, rgba(228,199,97,1) 70%, rgba(180,137,58,1) 90%, rgba(216,188,91,1) 100%);
  text-shadow: 1px 1px 5px rgba(216, 188, 91, 1), 1px 1px 5px rgba(216, 188, 91, 1);
  background-position-x: 0px
}
.cont_OpcionMenuPopUp:hover {
  text-shadow: 1px 1px 10px rgba(216, 188, 91, 1), 1px 1px 10px rgba(216, 188, 91, 1);
  background-position-x: 230px
}
.cont_imgOpcionMenuPopUp {
  filter: invert(1) opacity(0.3);
  scale: 1;
}
.cont_OpcionMenuPopUp:hover .cont_imgOpcionMenuPopUp {
  filter: invert(1) opacity(0.5);
  scale: 1.1;
}
</style>