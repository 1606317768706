import { ref } from 'vue';
import { createStore } from 'vuex'

export default createStore({
  state: {
    //logueo
    produccion: true,
    sesionActiva: false,
    esCliente: false,
    Cargando: true,
    mostrarMenuShopping: false,
    mostrarMenuProductos: false,
    urlApi: "",
    dataUsuario: {},
    leaderList: [],
    bloquearDigitacion: {},
    //nav
    menu: false,
    
    //Clientes
    estadoPedido: undefined,
    anuncio: "",
    mostrarPopUpClientes: false,
    PopUpMostrado: false,
    PopUpMostradoGuardar: false,
    
    ValorPedidoMinimoCatalogo: 160000,

    Targets: undefined,
    Generos: undefined,
    Categorias: undefined,
    Grupos: undefined,

    generoSeleccionadoHover: undefined,
    categoriaSeleccionadaHover: undefined,
    grupoSeleccionadoHover: undefined,
    
    paginaSeleccionadaProductos: undefined,
    generoSeleccionadoProductos: undefined,
    categoriaSeleccionadaProductos: undefined,
    grupoSeleccionadoProductos: undefined,
    
    generoSeleccionado: undefined,
    categoriaSeleccionada: undefined,
    grupoSeleccionado: undefined,

    paginaSeleccionada: undefined,
    precioSeleccionado: undefined,
    referenciaSeleccionada: undefined,

    productosCarrito: [],
    PedidoDB: [],
    tienePedido: false,
    modoCompra: false,

    productosBackOrder: [],
    UdsLimiteProductosCampActual: 9
  },
  getters: {
  },
  mutations: {
    mostrarMenu(){
      state.menu.value = !state.menu.value;
    },
    setUrlApi(val){
      state.urlApi.value = val
    },
    setDataUsuario(val){
      state.dataUsuario.value = val
    }
  },
  actions: {
  },
  modules: {
    
  }
})
