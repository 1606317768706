<template>
    <div class="VersionApp">{{ "V " + VersionApp }}</div>
    <div class="grid grid-cols-1">
        <div class="grid grid-cols-3 h-[70vh] md:h-[80vh] lg:h-[90vh]">
            <div class="slider hidden lg:block w-full col-span-2">
                <div class="feature">
                </div>
            </div>
            <div class="cont_Logueo flex w-full col-span-3 lg:col-span-1 top-0">
                <div class="cont_main">
                    <div class="cont_logo">
                        <img src="/img/JB/JB_Logo_Blanco.webp" alt="">
                    </div>
                    <div class="cont_form">
                        <div class="cont_datosLogueo">
                            <div class="cont_usuario">
                                <input id="user" name="user" type="number" autocomplete="usuario" placeholder="CEDULA" v-model="Usuario" @keypress.enter="focusContrasena()" />
                            </div>
                            <div class="cont_contrasena">
                                <input id="password" name="password" type="password" autocomplete="contrasena" placeholder="CONTRASEÑA" v-model="Contrasena" @keypress.enter="Login(Usuario,Contrasena)" />
                            </div>
                            <div class=" block rounded-lg py-2 px-4 bg-white text-red-700" v-if="ContrasenaIncorrecta">
                                <span>Usuario o Contraseña Incorrectos</span>
                            </div>
                            <div class=" text-xs text-white flex flex-row items-center w-full text-left">
                                <input class="mr-2 w-4 h-4" type="checkbox" name="" id="" v-model="AceptaPoliticaTratamientoDatos">
                                <span>Acepto la <router-link class="hover:underline" :to="'/AvisoLegal'">POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN</router-link></span>
                            </div>
                            <div class="cont_Ingresar">
                                <button type="submit" @click="Login(Usuario,Contrasena)"><i class="fas fa-sign-in-alt pr-2"></i>INGRESAR</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="absolute text-white bottom-[3rem] text-xs w-full">
                    <a class="hover:underline" href="https://juanabonitavirtual.com.co/docs/Pol%C3%ADtica%20Cambios%20y%20Devoluciones%20ANEXO%201.pdf">POLÍTICA DE CAMBIOS Y DEVOLUCIONES</a>
                </div>
                <div class="cont_RRSS absolute w-full bottom-[1rem] text-sm">
                    <div class="mx-auto flex flex-row">
                        <span>Síguenos</span>
                        <a href="https://www.facebook.com/Juanabonitacolombia/" target="_blank">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com/juanabonitacolombia/" target="_blank">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCCSIV-WKjF6TrT57Kvd59ag" target="_blank">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="cont_Informacion flex align-middle justify-center items-center h-[30vh] md:h-[20vh] lg:h-[10vh] bg-[#b81430] w-full bottom-0 left-0 overflow-hidden break-words">
            <div class="cont_DatosGenerales w-[80%] mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 bottom-[6rem] text-white text-center">
                <div class="Direccion">
                    <h2 class="titulo font-semibold text-sm lg:text-md"><i class="fa-solid fa-location-dot mr-2"></i>Dirección</h2>
                    <span class="texto block text-xs lg:text-sm">Cll 57 Sur #48-24 Sabaneta - Ant</span>
                </div>
                <div class="Teléfono">
                    <h2 class="titulo font-semibold text-sm lg:text-md"><i class="fa-solid fa-phone mr-2"></i>Teléfono</h2>
                    <span class="texto block text-xs lg:text-sm">Línea Fija (+57) 604 204 2334</span>
                    <span class="texto block text-xs lg:text-sm">WhatsApp SAC 317 405 1977</span>
                </div>
                <div class="Email">
                    <h2 class="titulo font-semibold text-sm lg:text-md"><i class="fa-solid fa-envelope mr-2"></i>Correo Electrónico</h2>
                    <span class="texto block text-xs lg:text-sm">contacto@juanabonita.com.co</span>
                </div>
                <div class="Email">
                    <h2 class="titulo font-semibold text-sm lg:text-md">Información</h2>
                    <a class="texto block text-xs lg:text-sm hover:underline" href="https://asesoras.juanabonita.com.co/sin-categoria/codigo-de-etica-acovedi/">Código de ética (Click Aqui)</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { version } from "../../../package.json"
import { computed, ref } from "@vue/runtime-core";
import { useStore } from 'vuex';

export default {
    name: 'app',
    setup(){
        const store = useStore()

        const Usuario = ref("")
        const Contrasena = ref("")

        const AceptaPoliticaTratamientoDatos = ref(false)

        const ContrasenaIncorrecta = ref(false)

        const expiracionCookies = 0.01

        const VersionApp = computed(()=>version.trim())

        store.state.urlApi = store.state.produccion 
        ? "https://juanabapl.juanabonita.com.co:446/JuanaBonitaAPI"
        : "http://192.168.1.135:45455"

        const focusContrasena = () => {
            document.getElementById("password").focus()
        }
        
        const Login = (usuario, contrasena) => {
            if(AceptaPoliticaTratamientoDatos.value){
                store.state.Cargando = true
                let datosLogueoUsuario = {"usuario": usuario,"clave": contrasena, Tipo: "PEDIDOS_CLIENTES"}
                axios
                    .post(store.state.urlApi + "/api/Token", datosLogueoUsuario)
                    .then((response) => {
                        ContrasenaIncorrecta.value = false
                        //console.log(response.data)
                        let infoUsuario = {
                            token: response.data.token,
                            idCliente: response.data.cliente.NumCodigo
                        }
                        store.state.anuncio = response.data.anuncio
                        Cookies.set('UsuarioJB', JSON.stringify(infoUsuario),{ expires: expiracionCookies })
                        store.state.dataUsuario = {
                            token: response.data.token,
                            infoCliente: response.data.cliente,
                            //leaderList: response.data.leaderList,
                            detalleFacturas: response.data.detalleFacturas
                        }
                        if(response.data.estadoPedido > 0){
                            store.state.tienePedido = true
                        }else{
                            store.state.tienePedido = false
                        }
                        //console.log(store.state.dataUsuario)
                        store.state.estadoPedido = response.data.estadoPedido
                        store.state.bloquearDigitacion = response.data.bloquearDigitacion
                        if(response.data.bloquearDigitacion.Bloquear){
                            store.state.dataUsuario.infoCliente.IdTipoFacturacion = 2
                        }
                        store.state.esCliente = true
                        store.state.sesionActiva = true
                        store.state.Cargando = false
                        ////console.log(store.state.esCliente)
                    })
                    .catch((error) => {
                        //console.log(error)
                        //Swal.fire(error.response.data)
                        ContrasenaIncorrecta.value = true
                        store.state.Cargando = false
                    })
            }else{
                Swal.fire({
                    icon: 'warning',
                    title: 'ATENCIÓN!',
                    text: "Debes aceptar la POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN para poder ingresar a la plataforma"
                })
            }
        }

        if(Cookies.get('UsuarioJB') == undefined){
            store.state.sesionActiva = false
            store.state.esCliente = false
            store.state.Cargando = false
        }else{
            store.state.Cargando = true
            store.state.dataUsuario = JSON.parse(Cookies.get('UsuarioJB'))
            //console.log(store.state.dataUsuario)
            //api/PedidosClientes/GetLeaderListVenta/202310
            //var infoLeaderList = JSON.parse(Cookies.get('infoLeaderList'))
            let token = ((Cookies.get('UsuarioJB')) != undefined) && ((Cookies.get('UsuarioJB')) != null) && ((Cookies.get('UsuarioJB')) != "") ? JSON.parse(Cookies.get('UsuarioJB')) : undefined
            //console.log(token)
            axios
            .get(store.state.urlApi + "/api/PedidosClientes/GetInfoLogueoCliente/" + token.idCliente, {
                headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token.token,
                    },
                })
                .then((response) => {
                    store.state.dataUsuario = {
                        token: token.token,
                        infoCliente: response.data.infoCliente,
                        //leaderList: response.data.leaderlist,
                        detalleFacturas: response.data.detalleFacturas  
                    }
                    if(response.data.estadoPedido > 0){
                        store.state.tienePedido = true
                    }else{
                        store.state.tienePedido = false
                    }
                    store.state.anuncio = response.data.anuncio
                    //console.log(store.state.dataUsuario)
                    store.state.estadoPedido = response.data.estadoPedido
                    store.state.bloquearDigitacion = response.data.bloquearDigitacion
                    if(response.data.bloquearDigitacion.Bloquear){
                        store.state.dataUsuario.infoCliente.IdTipoFacturacion = 2
                    }
                    store.state.esCliente = true
                    store.state.sesionActiva = true
                    store.state.Cargando = false
                })
                .catch((error) => {
                    //console.log(error)
                    Swal.fire({
                        icon: 'error',
                        title: error.name,
                        text: error.message
                    })
                    store.state.Cargando = false
                })
            ////console.log(store.state.dataUsuario)
        }
        return{
            VersionApp,
            Usuario,
            Contrasena,
            ContrasenaIncorrecta,
            AceptaPoliticaTratamientoDatos,
            focusContrasena,
            Login,
        }
    }
}
</script>

<style scoped>
.VersionApp {
    position: absolute;
    display: block;
    color: #ffffff;
    font-weight: lighter;
    top: 10px;
    right: 10px;
    font-size: 15px;
    z-index: 999;
}
.cont_Logueo {
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    right: 0;
    background-color: #f93a5a;
}
.cont_main {
    display: flex;
    position: relative;
    /* margin-top: 6rem; */
    font-size: 0.75rem;
    color: #707070;
    border-radius: 1rem;
    /* border: solid 0.1rem #b9b9b9; */
    padding: 1rem;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.cont_logo {
    position: relative;
    display: block;
    /* top: 0px; */
    /* left: 0px; */
    width: 20rem;
    margin: auto;
}
.cont_form {
    display: flex;
    width: 100%;
    height: 12rem;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.cont_datosLogueo {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    padding: 0rem 1.5rem;
    width: 100%;
    height: 100%;
}
.cont_usuario, .cont_contrasena {
    display: block;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}
input[type='number'], input[type='password'] {
    display: block;
    position: relative;
    width: 100%;
    /*border: solid 0.1rem #b9b9b9;*/
    border-radius: 2rem;
    /* border-bottom: solid 0.2rem #f9f9f9; */
    background: #f9f9f9;
    padding: 0.5rem 2rem;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    /* box-shadow: 0.1rem 0.1rem 0.3rem -0.2rem #000 inset; */
}
/*
input[type='text']:hover, input[type='password']:hover{
    box-shadow: 0rem 0rem 0.3rem -0.1rem #f93a5a;
    border: solid 0.1rem #f93a5a;
}
input[type='text']:disabled, input[type='password']:disabled{
    cursor: not-allowed;
    background: #eaeaea;
}*/
.cont_RestablecerContrasena {
    color: #fff;
    cursor: pointer;
}
.cont_Ingresar button {
    display: flex;
    position: relative;
    padding: 0.5rem 2rem;
    font-weight: 500;
    color: #fff;
    background: #0f233c;
    /* border: solid 0.1rem #f93a5a; */
    border-radius: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
/*
.cont_Ingresar button:hover {
    color: #fff;
    background: #f93a5a;
    border: solid 0.1rem #fff;
}*/
.cont_RRSS {
    display: flex;
    color: #fff;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 1rem;
}
.cont_RRSS a {
    width: 1rem;
    display: block;
    cursor: pointer;
    margin: 0px 0.5rem;
}
.cont_RRSS a svg {
    width: 100%;
}

.feature {
    animation: slide 30s;
}
.slider {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    height: 100%;
    animation: slide 30s infinite;
}
@keyframes slide {
    0%{
        background-image: url(./SliderLogin/1.webp);
    }
    12.5%{
        background-image: url(./SliderLogin/1.webp);
    }
    25%{
        background-image: url(./SliderLogin/2.webp);
    }
    37.5%{
        background-image: url(./SliderLogin/2.webp);
    }
    50%{
        background-image: url(./SliderLogin/3.webp);
    }
    62.5%{
        background-image: url(./SliderLogin/3.webp);
    }
    75%{
        background-image: url(./SliderLogin/4.webp);
    }
    87.5%{
        background-image: url(./SliderLogin/4.webp);
    }
    100%{
        background-image: url(./SliderLogin/1.webp);
    }
}
</style>