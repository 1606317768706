<template>
    <div class="cont-main">
        <div class="cont_inicioCliente">
            <div class="seccion sec-datos flex flex-col flex-wrap content-start items-start">
                <!--<h2>Datos</h2>-->
                <div class="cont_datos grid grid-cols-2 gap-6 w-full text-sm" :class="modoCompra ? 'xl:grid-cols-4' : 'xl:grid-cols-5'">
                    <div class="rounded-xl flex p-2 font-semibold text-sm bg-[#f93a5a] text-white shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] dato1 cursor-pointer duration-300 hover:scale-[1.02]" @click="GetPedido()" v-if="!modoCompra">
                        <div class="inline-flex items-center justify-center mr-4 text-xl text-center w-[2rem] h-[2rem] text-white rounded-full bg-[#0f233c]">
                            <i class="fa-solid fa-cart-shopping scale-"></i>
                        </div>
                        <div class=" inline-flex align-middle items-center h-full w-2/3 text-center">
                            <span class="w-full">{{ tienePedido ? 'VER PEDIDO' : 'INGRESA PEDIDO' }}</span>
                        </div>
                    </div>
                    <div class=" grid grid-cols-3 gap-6 " @click="Pagar()">
                        <div class="col-span-2 relative mx-auto align-middle items-center h-12 z-50  flex font-semibold text-sm text-white dato1 cursor-pointer duration-300 hover:scale-[1.02]">
                            <img class="rounded-full shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] max-h-12  mx-auto text-white" src="/img/JB/btn_PSE-DaviPlata_Blanco.webp" />
                        </div>
                        <a class=" relative mx-auto align-middle items-center w-full h-12 z-50 rounded-xl flex p-2 font-semibold text-sm bg-white shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] dato1 cursor-pointer duration-300 hover:scale-[1.02]" href="https://epayco.com/" target="_blank">
                            <img class=" max-h-10 mx-auto text-white" src="https://cdn.document360.io/88b1b912-ebe6-4677-9cf4-27af4e66c459/Images/Setting/Logo-EPAYCO---RGB-1.png" />
                        </a>
                    </div>
                    <div class="rounded-xl flex p-2 font-semibold text-sm bg-white shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] dato1">
                        <div class="inline-flex items-center align-middle justify-center mr-4 text-xl text-center w-[2rem] h-[2rem] text-white rounded-full p-[0.2rem]" style="background-image: url(/img/JB/logo_blanco.webp);">
                            <img src="/img/JB/Logo_JB.webp" class="" />
                        </div>
                        <div class=" inline-flex align-middle items-center h-full w-3/4 text-center">
                            <span class="w-full text-[10px] sm:text-sm">CAMPAÑA: {{ Campana.substring(4,6) + "-" + Campana.substring(0,4) }}</span>
                        </div>
                    </div>
                    <div class="rounded-xl flex p-2 font-semibold text-sm bg-white shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] dato2">
                        <div class="inline-flex items-center justify-center mr-4 text-xl text-center w-[2rem] h-[2rem] text-white rounded-full bg-teal-700">
                            <i class="fa-solid fa-hand-holding-dollar"></i>
                        </div>
                        <div class=" inline-flex align-middle items-center h-full w-2/3 text-center">
                            <span class="w-full">SALDO: {{ new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(saldoCliente) }}</span>
                        </div>
                    </div>
                    <div class="rounded-xl flex p-2 font-semibold text-sm bg-white shadow-gray-300 shadow-[0rem_0rem_0.5rem_0rem] dato2">
                        <div class="inline-flex items-center justify-center mr-4 text-xl text-center w-[2rem] h-[2rem] text-white rounded-full bg-teal-700">
                            <i class="fa-solid fa-hand-holding-dollar"></i>
                        </div>
                        <div class=" inline-flex align-middle items-center h-full w-2/3 text-center">
                            <span class="w-full">CUPO: {{ infoCliente.IdTipoFacturacion == 2 ? new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(0) : new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(cupoCliente) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="seccion mt-6 sec-Publicidad-Alertas grid grid-cols-1 lg:grid-cols-4 gap-6">
                <div class="cont_Publicidad flex flex-col flex-wrap content-start items-start lg:col-span-3">
                    <!--<h2>Publicidad</h2>-->
                    <div class="cont-img contenedorPublicidad rounded-2xl overflow-hidden cursor-pointer">
                        <a data-fancybox="Publicidad" data-src="/img/JB/Premio_1.webp">
                            <img src="/img/JB/Premio_1.webp" />
                        </a>
                    </div>
                </div>
                <div class="cont_Alertas flex flex-col flex-wrap content-start items-start">
                    <!--<h2>Alertas</h2>-->
                    <div class="contenedorAlertas grid grid-cols-3 lg:grid-cols-1 gap-6">
                        <div class="cont-img alerta alerta1 rounded-2xl overflow-hidden cursor-pointer">
                            <a data-fancybox="Alertas" data-src="/img/JB/Premio2.webp">
                                <img src="/img/JB/Premio2.webp" />
                            </a>
                        </div>
                        <div class=" cont-img alerta alerta2 rounded-2xl overflow-hidden cursor-pointer">
                            <a data-fancybox="Alertas" data-src="/img/JB/premio3.webp">
                                <img src="/img/JB/premio3.webp" />
                            </a>
                        </div>
                        <div class="cont-img alerta alerta3 rounded-2xl overflow-hidden cursor-pointer">
                            <a data-fancybox="Alertas" data-src="/img/JB/premio4.webp">
                                <img src="/img/JB/premio4.webp" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { computed, ref } from "@vue/runtime-core";
import { useStore } from 'vuex';

export default {
    name: 'Inicio',
    setup(){
        const store = useStore()
        document.querySelectorAll(".genero").forEach((element,index) => {
            element.style = ""
        });
        document.querySelectorAll(".genero i").forEach((element,index) => {
            element.style = ""
        });
        store.state.paginaSeleccionadaProductos = undefined

        store.state.generoSeleccionadoHover = undefined
        store.state.categoriaSeleccionadaHover = undefined
        store.state.grupoSeleccionadoHover = undefined

        store.state.paginaSeleccionadaProductos = undefined
        store.state.generoSeleccionadoProductos = undefined
        store.state.categoriaSeleccionadaProductos = undefined
        store.state.grupoSeleccionadoProductos = undefined

        store.state.generoSeleccionado = undefined
        store.state.categoriaSeleccionada = undefined
        store.state.grupoSeleccionado = undefined

        store.state.paginaSeleccionada = undefined
        store.state.precioSeleccionado = undefined
        store.state.referenciaSeleccionada = undefined

        store.state.generoSeleccionado = undefined
        store.state.Categorias = undefined
        store.state.categoriaSeleccionada = undefined
        store.state.Grupos = undefined
        store.state.grupoSeleccionado = undefined

        const infoCliente = computed(() => store.state.dataUsuario.infoCliente)

        if(infoCliente.value.Logesempleado){
            store.state.ValorPedidoMinimoCatalogo = 0
        }

        const Login = computed(() => !store.state.sesionActiva)

        const esCliente = computed(() => store.state.esCliente)

        const Campana = computed(() => store.state.dataUsuario.infoCliente.CampañaIngresoMicrocolsa)

        var CampanaPedido = localStorage.getItem("CampanaPedido")
        var idCliente = localStorage.getItem("idCliente")
        if((CampanaPedido == null || CampanaPedido != Campana.value) || idCliente != infoCliente.value.NumCodigo){
            localStorage.clear();
            localStorage.setItem("CampanaPedido", Campana.value)
            localStorage.setItem("idCliente", infoCliente.value.NumCodigo)
        }

        const modoCompra = computed(() => store.state.modoCompra)

        const saldoCliente = computed(() => store.state.dataUsuario.infoCliente.EncCarteras[0].ValorTotal)
        const cupoCliente = computed(() => store.state.dataUsuario.infoCliente.Tope)
        const ValorAPagar = ref(saldoCliente.value)

        const tienePedido = computed(() => store.state.tienePedido)

        const BloquearDigitacion = computed(() => store.state.bloquearDigitacion)

        const cerrarSesion = () => {
            Swal.fire({
                icon: 'warning',
                title: 'ATENCIÓN!',
                text: "Expiró la sesion"
            })
            store.state.Cargando = true
            Cookies.remove("UsuarioJB")
            store.state.Targets = undefined
            store.state.Generos = undefined
            store.state.Categorias = undefined
            store.state.Grupos = undefined
            store.state.generoSeleccionadoHover = undefined
            store.state.categoriaSeleccionadaHover = undefined
            store.state.grupoSeleccionadoHover = undefined
            store.state.generoSeleccionadoProductos = undefined
            store.state.categoriaSeleccionadaProductos = undefined
            store.state.grupoSeleccionadoProductos = undefined
            store.state.generoSeleccionado = undefined
            store.state.categoriaSeleccionada = undefined
            store.state.grupoSeleccionado = undefined
            store.state.paginaSeleccionada = undefined
            store.state.productosCarrito = []
            store.state.sesionActiva = false
            store.state.esCliente = false
            store.state.modoCompra = false
            store.state.tienePedido = false
            store.state.Cargando = false
            store.state.estadoPedido = 0
            store.state.anuncio = ""
            router.push("/")
        }

        const GetPedido = () => {
            store.state.Cargando = true
            let token = ((Cookies.get('UsuarioJB')) != undefined) && ((Cookies.get('UsuarioJB')) != null) && ((Cookies.get('UsuarioJB')) != "") ? JSON.parse(Cookies.get('UsuarioJB')) : undefined
            //console.log(token)
            if(token == undefined){
                cerrarSesion()
            }else{
                //console.log(BloquearDigitacion.value)
                if(BloquearDigitacion.value.Bloquear){
                    Swal.fire({
                        icon: 'error',
                        title: BloquearDigitacion.value.Titulo,
                        text: BloquearDigitacion.value.Mensaje
                    })
                    store.state.Cargando = false
                }else{
                    axios
                        .get(store.state.urlApi + "/api/PedidosClientes/GetPedidoAsesora/" + infoCliente.value.NumCodigo + "/" + Campana.value , {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token.token,
                            },
                        })
                        .then((response) => {
                            //console.log(response.data)
                            store.state.leaderList = response.data.leaderlist
                            if(response.data.strMensaje != ""){
                                store.state.modoCompra = false
                                store.state.tienePedido = true
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'ATENCIÓN!',
                                    text: response.data.strMensaje
                                })
                            }else{
                                store.state.modoCompra = true
                                if(response.data.estadoPedido == 0){
                                    store.state.tienePedido = false
                                    var PedidoCliente = JSON.parse(localStorage.getItem("PedidoCliente"))
                                    if(PedidoCliente != null){
                                        //console.log(PedidoCliente)
                                        store.state.productosCarrito = PedidoCliente
                                        //console.log(store.state.productosCarrito)
                                    }
                                }else{
                                    store.state.tienePedido = true
                                    store.state.productosCarrito = response.data.productosCarrito
                                    store.state.PedidoDB = response.data.productosCarrito
                                    store.state.PedidoDB.forEach((ele) => {
                                        ele.DesdeBD = true
                                        ele.CantidadBD = ele.Cantidad
                                    })
                                    //console.log(store.state.PedidoDB)
                                    //localStorage.setItem("PedidoCliente", JSON.stringify(store.state.productosCarrito));
                                }
                            }
                            store.state.Cargando = false
                        })
                        .catch((error) => {
                            //console.log(error)
                            if(error.response.state == 401){
                                cerrarSesion()
                                Swal.fire({
                                    icon: 'error',
                                    title: 'ATENCIÓN!',
                                    text: "Expiro la Sesion"
                                })
                            }else{
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'ATENCIÓN!',
                                    text: error.response.data.Message
                                })
                            }
                            store.state.Cargando = false
                        })
                }
            }
        }

        const Pagar = () => {
        Swal.fire({
            //title: 'Deseas Pagar?',
            text: "Ingresa el valor que deseas pagar:",
            icon: 'question',
            input: 'number',
            //inputLabel: 'Ingresa el valor que deseas pagar:',
            inputValue: saldoCliente.value,
            showCancelButton: true,
            confirmButtonText: 'Pagar',
            cancelButtonText: 'Atrás',
            confirmButtonColor: "#15803d",
            cancelButtonColor: "#b91c1c",
            reverseButtons: true
        }).then((result) => {
            if(result.isConfirmed){
                //console.log(result.value)
                ValorAPagar.value = result.value
                DaviviendaPSE()
            }
        })
        }
        const DaviviendaPSE = () => {
            store.state.Cargando = true
            var min = 10000000;
            var max = 99999999;

            var numeroAleatorio = Math.floor(Math.random() * (max - min + 1) + min);

            if (ValorAPagar.value >= 5000) {
                var handler = ePayco.checkout.configure({
                    key: 'e4522b2d2e7ba5a94206b70f9735d216',
                    test: false
                });

                var data = {
                    //Parametros compra (obligatorio)
                    name: "Juana Bonita",
                    description: "Vistete de moda, estilo y color",
                    invoice: numeroAleatorio,
                    currency: "cop",
                    amount: ValorAPagar.value,
                    tax_base: "0",
                    tax: "0",
                    country: "co",
                    lang: "es",

                    //Onpage="false" - Standard="true"
                    external: "true",

                    //Atributos opcionales
                    extra1: infoCliente.value.Numdocumento,
                    extra2: "extra2",
                    extra3: "extra3",
                    confirmation: store.state.urlApi + "/api/DaviviendaPSE",
                    response: location.origin + "/response.html",

                    //Atributos cliente
                    name_billing: infoCliente.value.PrimerNombre + " " + infoCliente.value.SegundoNombre + " " + infoCliente.value.PrimerApellido + " " + infoCliente.value.SegundoApellido,
                    address_billing: "",
                    type_doc_billing: "cc",
                    mobilephone_billing: "",
                    number_doc_billing: infoCliente.value.Numdocumento,

                    //atributo deshabilitación metodo de pago
                    //methodsDisable: ["TDC", "PSE", "SP", "CASH", "DP"],
                }

                handler.open(data)
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'VERIFIQUE EL VALOR!',
                    text: "EL VALOR DEBE SER MAYOR A $ 5.000."
                })
                store.state.Cargando = false
            }
        }


        return{
            Login,
            esCliente,
            infoCliente,
            saldoCliente,
            cupoCliente,
            Campana,

            modoCompra,
            tienePedido,

            Pagar,
            GetPedido,
        }
    }
}
</script>

<style>
.dato {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem;
}

.contenedorDatos {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    font-weight: 500;
    font-size: 1rem;
}

.iconoDato {
    display: flex;
    border-radius: 100%;
    width: 3.5rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    color: #fff;
    padding: 0.5rem;
}
.iconoPSE {
    position: relative;
    display: block;
    border-radius: 100%;
    width: 4rem;
    height: 3rem;
    color: #fff;
}

.iconoDato i {
    scale: 2;
}

.infoDato {
    font-size: 1.5rem;
}

.seccion h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.dato1 .iconoDato{
    background: #f93a5a;
}
.dato1 .iconoPSE{
    background: #f93a5a;
}
.dato2 .iconoDato{
    background: #01aca3;
}
.dato3 .iconoDato{
    background: #abb4bd;
}
.dato4 .iconoDato{
    background: #0f233c;
}
.Anuncio {
    display: block;
    position: relative;
    height: 8rem;
    border-radius: 1rem;
}
.Anuncio1{
    background: #d9eaf2;
}
.Anuncio2{
    background: #7ff9bc;
}
.Anuncio3{
    background: #f3879f;
}
.Anuncio4 {
    background: #60ccfb;
}
</style>